import React, { useState, useEffect, useContext } from 'react';
import { View, Text, TextInput, ScrollView, StyleSheet, Image, Pressable, Alert } from 'react-native';
import { useNavigation } from "@react-navigation/native"
import { Ionicons } from 'react-native-vector-icons';
import { Button } from 'react-native-elements';


import io from 'socket.io-client';
import axios from 'axios';
import Boton from './Boton';
import * as ImagePicker from 'expo-image-picker';
import BotonCirculo from './BotonCirculo';
import AddPost from './CrearPublicacion';
import { AuthContext } from './AuthContext';


const socket = io('http://20.55.39.129:4003');
const url = "http://20.55.39.129:4003/api/";


function Posts() {
  const [nickname, setNickname] = useState('');
  const [fecha, setFecha] = useState(Date);
  const [texto, setTexto] = useState('');
  const [imagen, setSelectedImage] = useState(null);
  const [barrio, setBarrio] = useState('');
  const [like, setLike] = useState('1');

  const [disabled, setDisabled] = useState(false);
  const [publicaciones, setPublicaciones] = useState([]);
  const [storedPublicaciones, setStoredPublicaciones] = useState([]);
  const [firstTime, setFirstTime] = useState(false);

  const user = useContext(AuthContext);
  const userEmail = user ? user.email : null;

  const navigation = useNavigation();


  const pickImageAsync = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      quality: 1,
    });

    if (!result.canceled) {
      setSelectedImage(result.assets[0].uri);
      console.log(result);
    } else {
      alert('You did not select any image.');
    }
  };


  useEffect(() => {
    const receivedPublicacion = (publicacion) => {
      setPublicaciones([publicacion, ...publicaciones]);
    };

    socket.on('publicacion', receivedPublicacion);

    return () => {
      socket.off('publicacion', receivedPublicacion);
    };
  }, [publicaciones]);

  if (!firstTime) {
    axios.get(url + "publicaciones").then((res) => {
      setStoredPublicaciones(res.data.publicaciones);
    });
    setFirstTime(true);
  }

  const handleNicknameSubmit = () => {
    console.log("autenticacion confirmada", userEmail);
    setNickname(userEmail);
    setDisabled(true);
  };

  const handleLikeSubmit = () => {
    console.log("autenticacion confirmada", userEmail);
    setLike(like + 1);
    Alert.alert("Te gusta esta publicacion <3" + like)
    setDisabled(true);
  };

  const onAddPublicacion = () => {
    console.log("Vamos a Crear publicacion!")
    navigation.navigate("Crear")
  };

  const handleMessageSubmit = () => {
    if (nickname !== '') {
      socket.emit('publicacion', nickname, fecha, texto, imagen, barrio, like);

      const newPublicacion = {
        from: "",
        fecha: fecha,
        body: texto,
        imagen: imagen,
        barrio: barrio,
        like: like
      };

      setPublicaciones([newPublicacion, ...publicaciones]);
      setTexto('');

      axios.post(url + 'save', {
        from: nickname,
        fecha: fecha,
        texto: texto,
        imagen: imagen,
        barrio: barrio,
        like: like

      });
    } else {
      alert('Para enviar mensajes debes establecer un nickname!!!');
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.chatContainer}>
        <Text style={styles.chatHeader}>MiBarrio</Text>
        <ScrollView style={styles.messageContainer}>
          {publicaciones.map((publicacion, index) => (
            <View
              key={index}
              style={[
                styles.message,
                {
                  alignSelf: publicacion.from === "" ? "flex-center" : "flex-center",
                  backgroundColor: publicacion.from === "" ? "#c471ed" : "#E6E6E6",
                },
              ]}
            >
              <Text>{publicacion.from}:{'\n'} {publicacion.fecha}{'\n'} {publicacion.body} {'\n'} Barrio: {publicacion.barrio} {'\n'}</Text>
              <Image
                source={{ uri: publicacion.imagen }}
                style={{ width: 300, height: 300,alignSelf: 'center',justifyContent: 'center' }}
              />
              <Text>{'\n'} {publicacion.like}</Text>
            </View>
          ))}


          <Text style={styles.storedMessagesHeader}>... Ultimas Publicaciones ...</Text>
          {storedPublicaciones.map((publicacion, index) => (
            <View
              key={index}
              style={[
                styles.message,
                {
                  alignSelf: publicacion.from === nickname ? "center" : "center",
                  backgroundColor: publicacion.from === nickname ? "#c471ed" : "#E6E6E6",
                },
              ]}
            >
              <Text style={styles.storedMessagesHeader}>{publicacion.from}:{'\n'} {publicacion.fecha}:{'\n'} {publicacion.texto} {'\n'} Barrio: {publicacion.barrio} {'\n'}</Text>
              <Image
                source={{ uri: publicacion.imagen }}
                style={{ width: 300, height: 300,alignSelf: 'center',justifyContent: 'center',marginBottom: 3 }}
              />
              <View style={styles.LikeInput} >
              <Button
                    icon={<Ionicons name="heart" size={24} color={"#b92b27"} />}
                    name="Like"
                    onPress={handleLikeSubmit}
                    buttonStyle={{ borderRadius: 20, backgroundColor: 'rgba(255, 255, 255, 0.3)',marginRight:5}}
                />
                
                <Text>{'\n'} {publicacion.like}</Text>
              </View>
              
            </View>
            
          ))}
          
        </ScrollView>
        
      </View>
      <View style={styles.optionsBoton}>
        <BotonCirculo onPress={onAddPublicacion} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#12c2e9",
    flex: 1,
    justifyContent: 'center',

  },
  chatContainer: {
    width: '99%',
    marginBottom: 5,
  },
  chatHeader: {
    fontSize: 30,
    textAlign: 'center',
    marginBottom: 5,
  },

  messageContainer: {
    backgroundColor: "#0F2027",
    maxHeight: 530,
    marginBottom: 1,
    marginLeft: 2,
    marginRight: 2,
  },
  message: {
    padding: 10,
    marginVertical: 5,
    borderRadius: 10,
    maxWidth: '90%',
  },
  storedMessagesHeader: {
    textAlign: 'center',
    marginTop: 5,
    fontStyle: 'italic',
  },
  optionsBoton: {
    justifyContent: 'center',
    marginTop: 3,
    alignSelf: 'center',
    marginBottom: 40
  },
  LikeInput: {
    flexDirection: 'row',
  },
});

export default Posts;