import React, { useContext } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { AuthContext } from './AuthContext';

export default function ProfilScreen() {
  const user = useContext(AuthContext);
  const userEmail = user ? user.email : null;

  return (
    <View style={styles.container}>
      <Text>Hola {userEmail}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
