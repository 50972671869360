import React from 'react';
import { Image, Text, StyleSheet, View, ScrollView, TouchableOpacity, TextInput, Button, Alert, StatusBar, LayoutAnimation } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AuthProvider } from './screens/AuthContext';

import { firebaseConfig } from './firebase-config';
import LoginScreen from "./screens/LoginScreen";
import RegistrationScreen from "./screens/RegistrationScreen";
import PostScreen from "./screens/PostScreen";
import HomeScreen from './screens/HomeScreen';



import NavigationScreen from "./screens/NavigationScreen";


const Stack = createNativeStackNavigator();

export default function App() {

  <firebaseConfig/>

  return (

    <NavigationContainer>
      <AuthProvider>
        <Stack.Navigator initialRouteName="Login">
          <Stack.Screen name="Login" component={LoginScreen} />
          <Stack.Screen name="Registro" component={RegistrationScreen} />
          <Stack.Screen name="Salir" component={NavigationScreen} />
          <Stack.Screen name="Crear" component={PostScreen} />
          <Stack.Screen name="Muro" component={HomeScreen} />

        </Stack.Navigator>
      </AuthProvider>
    </NavigationContainer>

  );
}

