import React, { useContext } from 'react';
import { View, Text, StyleSheet } from "react-native"
import { AuthContext } from './AuthContext';
import Posts from './Publicacion';


export default class HomeScreen extends React.Component {
  render() {
    return (
        <Posts/>
    );
  }
}

