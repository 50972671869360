import React from 'react';
import { View, Text, StyleSheet } from "react-native"

export default class MapScreen extends React.Component {
  render() {
    return (
      <View style={styles.container}>
        <Text>Esta opcion no se encuentra disponible en la version web </Text>
        <Text>Te invitamos a descargar la App movil.</Text>
       </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
