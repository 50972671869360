import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration

export const firebaseConfig = {
  apiKey: "AIzaSyCiZorjW2ydPUO6td1ETLTMXSEQ-XgOk2w",
  authDomain: "loging-social-65880.firebaseapp.com",
  projectId: "loging-social-65880",
  storageBucket: "loging-social-65880.appspot.com",
  messagingSenderId: "1003496802267",
  appId: "1:1003496802267:web:9bdd369880250b695e09fe"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default {auth,app};