import React from 'react';
import { Image, Text, StyleSheet, View, ScrollView, TouchableOpacity, TextInput, Button, Alert, StatusBar, LayoutAnimation } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Ionicons } from 'react-native-vector-icons';

import MapScreen from './MapScreen';
import HomeScreen from './HomeScreen';
import MessageScreen from './MessageScreen';
import ProfileScreen from './ProfileScreen';
import BienvenidaScreen from './BienvenidaScreen';


import LoginScreen from './LoginScreen';

const Tab = createBottomTabNavigator();


function NavigationScreen() {
  return (
    <Tab.Navigator
      initialRouteName="Imbox"
      screenOptions={{
        tabBarActiveTintColor: '#12c2e9',
        tabBarInactiveTintColor: '#FFF',
        tabBarActiveBackgroundColor: '#141517',
        tabBarInactiveBackgroundColor: '#141517',
        tabBarStyle: {
          display: 'flex'
        }
      }}
    >
       <Tab.Screen
      name="Bienvenida"
      component={BienvenidaScreen}
      options={{
        tabBarIcon: ({ color, size }) => <Ionicons name="ios-notifications" size={size} color={color} />,
      }}
    />
    <Tab.Screen
      name="Home"
      component={HomeScreen}
      options={{
        tabBarIcon: ({ color, size }) => (
          <Ionicons name="ios-home" size={size} color={color} />
        ),
        headerShown: false,
      }}
    />
    <Tab.Screen
        name="Mapa"
        component={MapScreen}
        options={{
          tabBarIcon: ({ focused }) => {
            return (
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#12c2e9",
                  borderRadius: 50,
                  borderWidth: 15,
                  borderColor: "#7303c0",
                }}
              >
                <Ionicons name="earth" size={40} color="#0f9b0f" />
              </View>
            );
          },
        }}
      />
      <Tab.Screen
      name="Message"
      component={MessageScreen}
      options={{
        tabBarIcon: ({ color, size }) => <Ionicons name="ios-chatbubbles" size={size} color={color} />,
      }}
    />
      <Tab.Screen
      name="Profile"
      component={ProfileScreen}
      options={{
        tabBarIcon: ({ color, size }) => <Ionicons name="ios-person" size={size} color={color} />,
      }}
    />
   
  </Tab.Navigator>
  )
}




export default NavigationScreen;