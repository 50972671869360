import React from 'react';
import { View, Text, StyleSheet, Image } from "react-native"

const profilePicture = require('../assets/MiBarrio.png');


export default class BienvenidaScreen extends React.Component {
  render() {
    return (
      <View>
      </View>,
      <View style={styles.container}>
        <Text style={styles.body}> Hola! Te saludamos desde MiBarrio App! </Text>
        <Text style={styles.centeredText}> En esta aplicación podrás comunicarte con los vecinos de tu barrio.</Text>
        <Text style={styles.centeredText}> A continuación selecciona la opción que deseas realizar:{"\n"} {"\n"}☻Para conversar con tu barrio selecciona Message.{"\n"}☻Para observar lo que comparten tus vecinos selecciona Home.{"\n"}</Text>
        <Text style={styles.centeredText}> {"\n"}Si deseas mirar o crear eventos en tu barrio, te invitamos a instalar la aplicación desde tu móvil.</Text>
        <Image source={{ uri: profilePicture }} style={styles.profilePicture} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f7ff00',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

  },
  body: {
    fontWeight: 'bold',
    fontSize: 35,
    color: '#96c93d',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
  profilePicture: {
    width: 100,
    height: 100,
    borderRadius: 20,
    borderColor: "#fff",
    borderWidth: 1,
    marginVertical: 30,
  },
});
