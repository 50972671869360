import React, { useContext } from 'react';
import { View, Text, StyleSheet } from "react-native"
import { AuthContext } from './AuthContext';
import Chat from "./chat";
import SomeComponent from './chat';


export default class MessageScreen extends React.Component {
  render() {
    return (
        <SomeComponent/>
        
    );
  }
}

