import React from 'react';
import { Image, Text, StyleSheet, View, ScrollView, TouchableOpacity, TextInput, Button, Alert, StatusBar, LayoutAnimation } from 'react-native';
import { BlurView } from 'expo-blur';

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { useNavigation } from "@react-navigation/native"
import { firebaseConfig } from '../firebase-config';




const uri = "https://cdn.pixabay.com/photo/2023/09/26/14/08/cartoon-8277556_1280.jpg"
const profilePicture = "https://cdn.pixabay.com/photo/2022/09/16/13/07/generated-7458584_1280.jpg"


function RegistrationScreen() {

  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const navigation = useNavigation();
  const [fullName, setFullName] = React.useState("")

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);


  const handleCreateAccount = () => {
    if (password !== confirmPassword) {
        Alert.alert("Passwords don't match.");
        return;
    }
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("Cuenta Creada!")
        const user = userCredential.user;
        console.log(user)
        navigation.navigate('Login')
      })
      .catch(error => {
        console.log(error)
        Alert.alert(error.message)
      })
  }
    


  const onFooterLinkPress = () => {
    navigation.navigate('Login')
  }



  return (
    <View style={styles.container}>
      <Image source={{ uri }} style={[styles.image, StyleSheet.absoluteFill]} />
      <View style={{ width: 50, height: 50, backgroundColor: "purple", position: "absolute" }}></View>
      <View style={{ width: 100, height: 100, backgroundColor: "yellow", top: 110, position: "absolute", borderRadius: 50 }}></View>
      <View style={{ width: 100, height: 100, backgroundColor: "green", bottom: 130, position: "absolute", transform: [{ rotate: "45deg" }] }}></View>
      <ScrollView contentContainerStyle={{
        flex: 1,
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}>
              <BlurView intensity={50}>
                  <View style={styles.login}>
                      <Image source={{ uri: profilePicture }} style={styles.profilePicture} />
                      <View>
                          <Text style={{ fontSize: 17, fontWeight: "400", color: "white" }}>Fullname:</Text>
                          <TextInput onChangeText={(text) => setFullName(text)} style={styles.input} placeholder='name_14'></TextInput>
                      </View>
                      <View>
                          <Text style={{ fontSize: 17, fontWeight: "400", color: "white" }}>E-mail:</Text>
                          <TextInput onChangeText={(text) => setEmail(text)} style={styles.input} placeholder='ejemplo@gmail.com'></TextInput>
                      </View>
                      <View>
                          <Text style={{ fontSize: 17, fontWeight: "400", color: "white" }}>Contraseña:</Text>
                          <TextInput onChangeText={(text) => setPassword(text)} style={styles.input} placeholder='**********' secureTextEntry={true}></TextInput>
                      </View>
                      <View>
                          <Text style={{ fontSize: 17, fontWeight: "400", color: "white" }}>Confirmar Contraseña:</Text>
                          <TextInput onChangeText={(text) => setConfirmPassword(text)} style={styles.input} placeholder='**********' secureTextEntry={true}></TextInput>
                      </View>

                      <TouchableOpacity onPress={handleCreateAccount} style={[styles.button, { backgroundColor: "#daff" }]}>
                          <Text style={{ fontSize: 17, fontWeight: "400", color: "white" }}>Crear Cuenta</Text>
                      </TouchableOpacity>
                      <View style={styles.footerView}>
                          <Text style={styles.footerText}>Iniciar Sesion? <Text onPress={onFooterLinkPress} style={styles.footerLink}>Log in</Text></Text>
                      </View>
                  </View>
              </BlurView>
      </ScrollView>
    </View>
  );
}



const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
  },
  login: {
    width: 350,
    height: 500,
    borderColor: "#fff",
    borderWidth: 2,
    borderRadius: 10,
    padding: 10,
    alignItems: "center",
  },
  profilePicture: {
    width: 50,
    height: 50,
    borderRadius: 50,
    borderColor: "#fff",
    borderWidth: 1,
    marginVertical: 1,
  },
  input: {
    width: 250,
    height: 40,
    borderColor: "#fff",
    borderWidth: 2,
    borderRadius: 10,
    padding: 10,
    marginVertical: 5,
    backgroundColor: "#ffffff90",
    marginBottom: 5,
  },
  button: {
    width: 250,
    height: 40,
    borderRadius: 10,
    backgroundColor: "#00CFEB90",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 10,
    borderColor: "#fff",
    borderWidth: 1,
  },
});

export default RegistrationScreen;