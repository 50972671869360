import React, { useState, useEffect, useContext } from 'react';
import { View, Text, TextInput, ScrollView, StyleSheet } from 'react-native';
import io from 'socket.io-client';
import axios from 'axios';
import { Ionicons } from 'react-native-vector-icons';
import { Button } from 'react-native-elements';
import { AuthContext } from './AuthContext';




const socket = io('http://20.55.39.129:4001');
const url = "http://20.55.39.129:4001/api/";

function SomeComponent() {

  const user = useContext(AuthContext);
  const userEmail = user ? user.email : null;

  const [nickname, setNickname] = useState(userEmail);
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [storedMessages, setStoredMessages] = useState([]);
  const [firstTime, setFirstTime] = useState(false);
 
  

  useEffect(() => {
    const receivedMessage = (message) => {
      setMessages([message, ...messages]);
    };

    socket.on('message', receivedMessage);

    return () => {
      socket.off('message', receivedMessage);
    };
  }, [messages]);

  if (!firstTime) {
    axios.get(url + "messages").then((res) => {
      setStoredMessages(res.data.messages);
    });
    setFirstTime(true);
  }

  {/*
    const handleNicknameSubmit = () => {
        console.log("autenticacion confirmada", userEmail);
        setNickname(userEmail);
        setDisabled(true);
    }
  */}


  const handleMessageSubmit = () => {
    if (nickname !== '') {
      socket.emit('message', message, nickname);

      const newMessage = {
        body: message,
        from: ''
      };

      setMessages([newMessage, ...messages]);
      setMessage('');

      axios.post(url + 'save', {
        message: message,
        from: nickname
      });
    } else {
      alert('Para enviar mensajes debes establecer un nickname!!!');
    }
  };

    return (
        <View style={styles.container}>
            <Text style={styles.chatHeader}>CHAT MiBarrio</Text>
            <View style={styles.chatContainer}>
              {/*}
                    <Button
                        title="Establecer Nombre"
                        onPress={handleNicknameSubmit}
                        disabled={disabled}
                    />

              */}
            </View>
            <ScrollView style={styles.messageContainer}>
                {messages.map((message, index) => (
                    <View key={index} style={[
                        styles.message,
                        {
                            alignSelf: message.from === "" ? "flex-end" : "flex-start",
                            backgroundColor: message.from === "" ? "#c471ed" : "#E6E6E6",
                        },
                    ]}
                    >
                        <Text>{message.from}: {message.body}</Text>
                    </View>
                ))}
                <Text style={styles.storedMessagesHeader}>... Mensajes guardados ...</Text>
                {storedMessages.map((message, index) => (
                    <View
                        key={index}
                        style={[
                            styles.message,
                            {
                                alignSelf: message.from === nickname ? "flex-end" : "flex-start",
                                backgroundColor: message.from === nickname ? "#c471ed" : "#E6E6E6",
                            },
                        ]}
                    >
                        <Text>{message.from}: {message.message}</Text>
                    </View>
                ))}
            </ScrollView>
            <View style={styles.messageForm}>
                <TextInput
                    style={styles.messageInput}
                    placeholder="  Mensaje..."
                    onChangeText={(text) => setMessage(text)}
                    value={message}
                />
                <Button
                    icon={<Ionicons name="send" size={24} color="#4286f4" />}
                    name="Enviar"
                    onPress={handleMessageSubmit}
                    buttonStyle={{ borderRadius: 20, backgroundColor: 'rgba(255, 255, 255, 0.3)',marginRight:5}} // Añade el borderRadius aquí
                    titleStyle={{ color: '#4286f4' }} // Estilo del texto del botón
                />
                
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#12c2e9",
    flex: 1,
    justifyContent: 'center',
  },
  chatContainer: {
    width: '98%',
    flexDirection: 'row-reverse',
    marginBottom: 10,
  },
  chatHeader: {
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 10,
  },
  nicknameInput: {
    backgroundColor: "#FFFFFF",
    flex: 1,
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginRight: 10,
  },
  messageForm: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  messageInput: {
    backgroundColor: "#FFFFFF",
    flex: 1,
    height: 50,
    borderColor: 'gray',
    borderWidth: 1,
    marginRight: 5,
    marginLeft: 5,
    borderRadius: 20,
    
  },
  messageContainer: {
    backgroundColor: "#0F2027",
    maxHeight: 500,
    marginBottom: 5,
    marginLeft: 2,
    marginRight: 2,

  },
  message: {
    padding: 10,
    marginVertical: 5,
    borderRadius: 10,
    maxWidth: '80%',
  },
  storedMessagesHeader: {
    textAlign: 'center',
    marginTop: 10,
    fontStyle: 'italic',
  },
});

export default SomeComponent;