import React, { useState, useEffect, useContext } from 'react';
import { View, Text, TextInput, Button, ScrollView, StyleSheet, Image, Pressable } from 'react-native';
import io from 'socket.io-client';
import axios from 'axios';
import Boton from './Boton';
import { AuthContext } from './AuthContext';
import * as ImagePicker from 'expo-image-picker';
import { useNavigation } from "@react-navigation/native"


const socket = io('http://20.55.39.129:4003');
const url = "http://20.55.39.129:4003/api/";


function AddPost() {

  const user = useContext(AuthContext);
  const userEmail = user ? user.email : null;

  const [nickname, setNickname] = useState(userEmail);
  const [fecha, setFecha] = useState(Date);
  const [texto, setTexto] = useState('');
  const [imagen, setSelectedImage] = useState(null);
  const [barrio, setBarrio] = useState('');
  const [like, setLike] = useState('1');

  const [disabled, setDisabled] = useState(false);
  const [publicaciones, setPublicaciones] = useState([]);
  const [storedPublicaciones, setStoredPublicaciones] = useState([]);
  const [firstTime, setFirstTime] = useState(false);

  

  const navigation = useNavigation();


  const pickImageAsync = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      quality: 1,
    });

    if (!result.canceled) {
      setSelectedImage(result.assets[0].uri);
      console.log(result);
    } else {
      alert('No has Seleccionado Niguna Imagen.');
    }
  };


  useEffect(() => {
    const receivedPublicacion = (publicacion) => {
      setPublicaciones([publicacion, ...publicaciones]);
    };

    socket.on('publicacion', receivedPublicacion);

    return () => {
      socket.off('publicacion', receivedPublicacion);
    };
  }, [publicaciones]);

  if (!firstTime) {
    axios.get(url + "publicaciones").then((res) => {
      setStoredPublicaciones(res.data.publicaciones);
    });
    setFirstTime(true);
  }


  const handleMessageSubmit = () => {
    if (nickname !== '') {
      socket.emit('publicacion', nickname, fecha, texto, imagen, barrio, like);

      const newPublicacion = {
        from: "",
        fecha: fecha,
        body: texto,
        imagen: imagen,
        barrio: barrio,
        like: like
      };

      setPublicaciones([newPublicacion, ...publicaciones]);
      setTexto('');

      axios.post(url + 'save', {
        from: nickname,
        fecha: fecha,
        texto: texto,
        imagen: imagen,
        barrio: barrio,
        like: like

      });
    } else {
      alert('Para enviar mensajes debes establecer un nickname!!!');
    }
    console.log("Publicacion Creada!")
    navigation.navigate("Salir")
  };

  return (
    <View style={styles.container}>
      <View style={styles.chatContainer}>
        <Text style={styles.chatHeader}>MiBarrio</Text>

        <View style={styles.nicknameForm}>

        </View>
        <View style={styles.messageForm}>
          <TextInput
            style={styles.messageInput}
            placeholder="Texto..."
            onChangeText={(text) => setTexto(text)}
            value={texto}
          />
        </View>

        <View style={styles.nicknameForm}>
          <TextInput
            style={styles.nicknameInput}
            placeholder="Barrio..."
            onChangeText={(text) => setBarrio(text)}
            value={barrio}
          />
        </View>
        <View >
          <Boton theme="primary" label="Subir Imagen" onPress={pickImageAsync} />
        </View>
        <View>
          <Button
            title="Publicar"
            onPress={handleMessageSubmit}
          />
        </View>


      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#12c2e9",
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chatContainer: {
    width: '80%',
  },
  chatHeader: {
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 10,
  },
  nicknameForm: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  nicknameInput: {
    backgroundColor: "#FFFFFF",
    flex: 1,
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginRight: 10,
  },
  messageForm: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  footerContainer: {
    flex: 1 / 3,
    alignItems: 'center',
  },
  messageInput: {
    backgroundColor: "#FFFFFF",
    flex: 1,
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginRight: 10,
  },
  messageContainer: {
    backgroundColor: "#0F2027",
    maxHeight: 400,
    marginBottom: 10,
  },
  message: {
    padding: 10,
    marginVertical: 5,
    borderRadius: 10,
    maxWidth: '80%',
  },
  storedMessagesHeader: {
    textAlign: 'center',
    marginTop: 10,
    fontStyle: 'italic',
  },
});

export default AddPost;