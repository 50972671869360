import React, { useContext } from 'react';
import { View, Text, StyleSheet } from "react-native"
import { AuthContext } from './AuthContext';
import AddPost from './CrearPublicacion';


export default class HomeScreen extends React.Component {
  render() {
    return (
        <AddPost/>
    );
  }
}

